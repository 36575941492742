.home-page {

  // height: 100vh;
  // overflow: auto;
  // scroll-behavior: smooth;
  .col {
    @media all and (max-width: 1200px) {
      flex-basis: initial;
      flex-grow: initial;
    }
  }

  .container {
    max-width: 1420px;
    padding-left: 30px;
    padding-right: 30px;

    @media all and (max-width: 1921px) {
      max-width: 1280px;
      padding-left: 25px;
      padding-right: 25px;
    }

    @media all and (max-width: 1200px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  a {
    color: black;
  }


  /////////////////// 
  /////////////////// mainsection
  /////////////////// 

  .mainSection {
    height: auto;
    padding-top: 20vh;
    padding-bottom: 15vh;
    overflow-x: hidden;

    @media all and (max-width: 1921px) {
      padding-top: 20vh;
      padding-bottom: 12vh;
    }

    .container {
      .mainSection__mainImgCol {
        background: #F7F7F7;
        align-items: center;
        display: flex;

        @media all and (max-width: 768px) {
          display: none;
        }


        &:after {
          content: '';
          position: absolute;
          display: block;
          background: #F7F7F7;
          height: 100%;
          border-radius: 4px;
          width: 100vw;
          transform: translate3d(-100%, 0, 0)
        }

        .box {
          padding-bottom: 40px;
          padding-top: 40px;
          padding-right: 10px;

          img {
            width: 100%;
            height: auto;
          }
        }
      }

      .mainSection__descrCol {
        align-items: center;
        display: flex;
        padding-right: 14%;

        @media all and (max-width: 1200px) {
          padding-right: 8%;
        }

        &:after {
          content: '';
          position: absolute;
          display: block;
          background: #FAC85A;
          height: 100%;
          width: 800%;
          transform: translate3d(12.2%, 0, 0);
          border-radius: 4px;

          @media all and (max-width: 1921px) {
            transform: translate3d(12%, 0, 0)
          }

          @media all and (max-width: 768px) {
            transform: translate3d(11.8%, 0, 0)
          }

        }

        h2 {
          font-weight: bold;
          font-size: 4rem;
          line-height: 1.2;

          @media all and (max-width: 1921px) {
            font-size: 3.4rem;
          }

          @media all and (max-width: 1200px) {
            font-size: 3rem;
          }

          @media all and (max-width: 768px) {
            font-size: 2.6rem;
          }

        }

        p {
          margin-top: 20px;
          font-weight: normal;
          font-size: 2.2rem;
          line-height: 1.3;

          @media all and (max-width: 1921px) {
            font-size: 2rem;
          }

          @media all and (max-width: 1200px) {
            font-size: 1.8rem;
          }

          @media all and (max-width: 768px) {
            font-size: 1.6rem;
          }
        }
      }
    }
  }


  /////////////////// 
  /////////////////// animSection
  /////////////////// 

  .animSection {
    .container {
      text-align: center;

      @media all and (max-width: 1200px) {
        max-width: 700px;
      }

      .arrowJump {
        width: 75px;
        margin: auto;
        transform: translateY(35px);

        @media all and (max-width: 1200px) {
          display: none;
        }
      }

      .row {


        .col {
          h3 {
            font-size: 1.3rem;
            line-height: 1.2;
            width: 150px;
            margin: 0px auto 0px auto;

            @media all and (max-width: 1200px) {
              font-size: 1.6rem;
              font-weight: 500;
              width: auto;
              margin-bottom: -15px;
            }
          }

          .box {
            padding: 15px 0px;
            // border: 1.5px solid black;
            border-radius: 4px;

            @media all and (max-width: 1200px) {
              margin-bottom: 20px;
              padding: 25px 5px;
            }

            @media all and (max-width: 576px) {
              padding: 25px 0px;
            }

            h2 {
              font-weight: bold;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-50%, 0, 0);
              opacity: 0.5;
              color: #FAC85A;

              @media all and (max-width: 1200px) {
                font-size: 2.5rem;
              }
            }

            .videoWrapper {
              border: 1.5px solid black;
              padding: 15px;
              border-radius: 4px;

              video {
                // opacity: 0.75;
                width: 100%;
                // height: 35vh;
                margin-bottom: 0px;

                padding: 1px;
                // box-shadow: 0px 0px 0px 1.5px black;
              }
            }
          }
        }
      }
    }
  }




  /////////
  ////////createProjectSection
  ////////


  .createProjectSection {
    padding-top: 15vh;

    @media all and (max-width: 576px) {
      padding-top: 50px;
    }

    .introBox {
      background: #F7F7F7;
      max-width: 880px;
      padding: 90px 60px;
      text-align: center;
      margin: auto;
      display: flex;
      flex-direction: column;

      @media all and (max-width: 1921px) {
        padding: 80px 60px;
        max-width: 860px;
      }

      @media all and (max-width: 1200px) {
        // padding: 50px 10px 40px 10px;
      }

      @media all and (max-width: 576px) {
        padding: 50px 20px 40px 20px;
      }

      h4 {
        font-weight: bold;
        font-size: 3.6rem;
        line-height: 1.1;
        padding-bottom: 30px;

        @media all and (max-width: 1921px) {
          font-size: 3rem;
        }

        @media all and (max-width: 576px) {
          font-size: 2.2rem;
          padding-bottom: 20px;
        }

        @media all and (max-width: 500px) {
          font-size: 1.8rem;
          text-align: left;
        }
      }

      p {
        padding-top: 20px;
        margin-bottom: 0px;
        font-weight: 300;
        font-size: 2rem;
        line-height: 180%;

        @media all and (max-width: 1921px) {
          font-size: 1.8rem;
          padding-top: 10px;
          padding: 0px 30px;
        }

        @media all and (max-width: 576px) {
          font-size: 1.6rem;
          padding: 0px;
          line-height: 1.5;
        }

        @media all and (max-width: 500px) {
          text-align: left;
          font-size: 1.5rem;
        }
      }
    }

    .stepArrow {
      @media all and (max-width: 1200px) {
        margin-top: 40px;
      }

      @media all and (max-width: 576px) {
        margin-bottom: 20px;
        margin-top: 0px;

        &:before {
          content: '';
          display: block;
          position: absolute;
          height: 30px;
          left: 50%;
          transform: translate3d(-50%, 0, 0);
          z-index: 2;
          width: 30px;
          background: white;
        }
      }

      img {
        @media all and (max-width: 1921px) {
          height: 150px;
        }

        @media all and (max-width: 576px) {
          height: 120px;
          position: relative;
          z-index: 1;
        }
      }
    }

    .stepsBox {
      padding-top: 10vh;
      padding-bottom: 15vh;

      @media all and (max-width: 1921px) {
        padding-top: 8vh;
      }

      @media all and (max-width: 1200px) {
        padding-left: 10%;
      }

      @media all and (max-width: 576px) {
        padding-left: 0px;
      }

      .row {
        padding-top: 5px;
        padding-bottom: 5px;
      }

      .col {
        align-self: center;
        text-align: center;
        flex-basis: initial;
        flex-grow: initial;
      }

      .stepImg {
        padding-right: 30px;

        @media all and (max-width: 1200px) {
          transform: translateY(25%);
          margin-top: -30px;
        }

        @media all and (max-width: 576px) {
          transform: none;
          margin-top: auto;
        }

        img {
          width: 100%;
          max-width: 300px;

          @media all and (max-width: 576px) {
            max-width: 260px;
            margin-top: 10px;
            margin-bottom: 40px;
          }
        }

      }

      .stepNum {
        font-weight: 900;
        font-size: 13rem;
        line-height: 1;
        height: 100%;
        color: #EEEEEE;

        @media all and (max-width: 1921px) {
          font-size: 12rem;
        }

        @media all and (max-width: 1200px) {
          margin-bottom: 20px;
        }

        @media all and (max-width: 576px) {
          font-weight: 500;
          font-size: 4rem;
          // border: 1px solid black;
          max-width: 56px;
          padding-top: 8px;
          padding-bottom: 5px;
          margin: auto;
          border-radius: 100px;
          margin-bottom: 10px;
          background: #FAC85A;
          color: white;
          box-shadow:
            4px 1px 0px -1px white,
            4px 1px 0px 0px black,
            inset 4px 1px 0px -1px #FAC85A,
            inset 4px 1px 0px 0px black,
        }
      }

      .stepDescr {
        text-align: left;
        padding-left: 30px;

        @media all and (max-width: 1200px) {
          padding-left: 15%;
          max-width: 600px;
        }

        @media all and (max-width: 576px) {
          padding-left: 0px;
        }

        h5 {
          font-weight: bold;
          font-size: 2.2rem;
          line-height: 110%;
          margin-bottom: 20px;

          @media all and (max-width: 1921px) {
            margin-bottom: 15px;
            font-size: 2rem;
          }

          @media all and (max-width: 576px) {
            font-size: 1.8rem;
          }
        }

        p {
          font-weight: 300;
          font-size: 2rem;
          line-height: 1.8;
          margin-bottom: 0px;

          @media all and (max-width: 1921px) {
            font-size: 1.8rem;
          }

          @media all and (max-width: 576px) {
            font-size: 1.6rem;
            line-height: 1.5;
          }

          @media all and (max-width: 500px) {
            font-size: 1.5rem;
          }
        }
      }
    }
  }



  //////////
  ///////// trustSection
  /////////


  .trustSection {
    text-align: center;
    padding-top: 120px;
    padding-bottom: 80px;
    background: #F7F7F7;

    @media all and (max-width: 1921px) {
      padding-top: 100px;
      padding-bottom: 70px;
    }

    @media all and (max-width: 576px) {
      padding-top: 70px;
      padding-bottom: 40px;
    }

    h4 {
      font-size: 3.2rem;
      font-weight: bold;
      padding-bottom: 8px;

      @media all and (max-width: 1921px) {
        font-size: 3rem;
      }

      @media all and (max-width: 1200px) {
        font-size: 2.8rem;
      }

      @media all and (max-width: 576px) {
        font-size: 2.2rem;
      }
    }

    p {
      font-size: 2rem;
      font-weight: 300;
      margin-bottom: 30px;
      // margin-bottom: 60px;

      @media all and (max-width: 1921px) {
        font-size: 1.8rem;
        margin-bottom: 40px;
      }

      @media all and (max-width: 576px) {
        font-size: 1.6rem;
      }
    }

    .logosWrapper {
      display: flex;
      flex-wrap: wrap;

      a {
        width: auto;
        margin: 30px auto;

        @media all and (max-width: 576px) {
          margin: 20px auto;
        }

        img {
          width: 180px;
          margin-right: 30px;
          margin-left: 30px;

          @media all and (max-width: 1921px) {
            width: 160px;
          }

          @media all and (max-width: 576px) {
            width: 140px;
          }
        }
      }
    }
  }


  /////////
  //////// togetherSection
  /////////

  .togetherSection {
    padding-top: 10vh;

    @media all and (max-width: 576px) {
      padding-top: 5vh;
    }

    .row {
      .col {
        justify-content: center;
        display: flex;

        @media all and (max-width: 1200px) {
          flex-basis: 0;
          flex-grow: 1;
        }

        @media all and (max-width: 576px) {
          flex-basis: initial;
          flex-grow: initial;
          padding-bottom: 30px;
        }

        .togetherJumbDescr {
          margin: auto;
          padding-right: 10%;

          @media all and (max-width: 576px) {
            padding: 0px;
          }

          h3 {
            font-weight: bold;
            font-size: 4rem;
            line-height: 1.1;

            @media all and (max-width: 1921px) {
              font-size: 3.4rem;
            }

            @media all and (max-width: 1200px) {
              font-size: 3rem;
            }

            @media all and (max-width: 768px) {
              font-size: 2.6rem;
            }

          }

          p {
            margin-top: 15px;
            font-weight: 300;
            font-size: 2rem;
            line-height: 1.8;

            @media all and (max-width: 1921px) {
              font-size: 1.8rem;
            }

            @media all and (max-width: 1200px) {
              font-size: 1.6rem;
            }

            @media all and (max-width: 576px) {
              font-size: 1.5rem;
              line-height: 1.5;
            }

            @media all and (max-width: 500px) {
              font-size: 1.4rem;
            }

            strong {
              font-weight: bold;
            }
          }
        }

        img {
          width: 100%;
        }

      }

      .arrowRollDown {
        @media all and (max-width: 1200px) {
          padding-bottom: 0px;
        }

        img {
          max-height: 200px;

          @media all and (max-width: 1921px) {
            max-height: 180px;
          }

          @media all and (max-width: 1200px) {
            max-height: 160px;
            padding-right: 50%;
            margin-top: 10px;
          }

          @media all and (max-width: 576px) {
            max-height: 140px;
          }
        }
      }

    }
  }

  .suggestMostImportantSection {
    padding-top: 5vh;
    padding-bottom: 15vh;

    @media all and (max-width: 1921px) {
      padding-top: 5vh;
      padding-bottom: 12vh;
    }

    @media all and (max-width: 1200px) {
      padding-top: 0px;
      padding-bottom: 8vh;
    }

    @media all and (max-width: 576px) {
      padding-bottom: 2vh;
    }

    h4 {
      font-size: 3.2rem;
      font-weight: bold;
      text-align: center;
      padding-bottom: 100px;
      padding-top: 50px;

      @media all and (max-width: 1921px) {
        font-size: 3rem;
      }

      @media all and (max-width: 1200px) {
        font-size: 2.8rem;
        padding-bottom: 60px;
      }

      @media all and (max-width: 576px) {
        font-size: 2.2rem;
        padding-bottom: 50px;
      }
    }

    .col {
      @media all and (max-width: 1200px) {
        display: flex;
        height: 150px;
      }

      @media all and (max-width: 768px) {
        height: auto;
      }

      @media all and (max-width: 576px) {
        flex-direction: column;
      }

      img {
        height: 145px;

        @media all and (max-width: 1921px) {
          height: 130px;
        }

        @media all and (max-width: 1200px) {
          height: auto;
          width: 240px;
          padding-right: 30px;
        }

        @media all and (max-width: 576px) {
          height: auto;
          width: 80%;
          margin-left: 10%;
          margin-bottom: 10px;
          padding-right: 0px;
        }

      }

      h5 {
        text-align: left;
        margin-top: 50px;
        font-size: 2rem;
        font-weight: bold;

        @media all and (max-width: 1921px) {
          font-size: 1.8rem;
        }

        @media all and (max-width: 1200px) {
          margin-top: 10px;
        }

        @media all and (max-width: 576px) {
          margin-top: 20px;
        }
      }

      p {
        text-align: left;
        font-weight: 300;
        font-size: 1.6rem;
        line-height: 1.8;
        margin-top: 20px;

        @media all and (max-width: 1921px) {
          font-size: 1.5rem;
          margin-top: 10px;
        }

        @media all and (max-width: 1200px) {
          font-size: 1.4rem;
        }

        @media all and (max-width: 576px) {
          margin-bottom: 60px;
        }

        strong {
          font-weight: bold;
        }
      }
    }

    .colLeft {
      padding-right: 8%;
    }

    .colMiddle {
      padding-left: 4%;
      padding-right: 4%;
    }

    .colRight {
      padding-left: 8%;
    }

    @media all and (max-width: 1200px) {

      .colRight,
      .colLeft,
      .colMiddle {
        padding-right: 15px;
        padding-left: 15px;
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
  }

  .actionSection {
    padding-top: 8vh;
    padding-bottom: 7vh;
    // background: #F7F7F7;
    background: #FAC85A;

    @media all and (max-width: 1200px) {
      padding-top: 4vh;
      padding-bottom: 3vh;
    }

    .row {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .col {
      align-self: center;

      @media all and (max-width: 1200px) {
        flex-basis: 0;
        flex-grow: 1;
      }

      @media all and (max-width: 576px) {
        flex-basis: initial;
        flex-grow: initial;
      }
    }

    h4 {
      font-size: 3.2rem;
      font-weight: bold;

      @media all and (max-width: 1921px) {
        font-size: 3rem;
      }

      @media all and (max-width: 1200px) {
        font-size: 2.8rem;
      }

      @media all and (max-width: 768px) {
        font-size: 2.5rem;
      }

      @media all and (max-width: 576px) {
        font-size: 2.2rem;
      }
    }

    h5 {
      font-weight: bold;
      font-size: 2rem;
      line-height: 130%;
      max-width: 320px;
      padding-bottom: 15px;

      @media all and (max-width: 1921px) {
        font-size: 1.8rem;
        max-width: 300px;
      }

      @media all and (max-width: 1200px) {
        font-size: 1.5rem;
      }

      @media all and (max-width: 768px) {
        font-size: 1.4rem;
        line-height: 1.5;
      }

      @media all and (max-width: 576px) {
        margin-top: 10px;
        max-width: 100%;
      }

    }

    p {
      font-weight: 300;
      font-size: 2rem;
      line-height: 180%;

      @media all and (max-width: 1921px) {
        font-size: 1.8rem;
      }

      @media all and (max-width: 1200px) {
        font-size: 1.6rem;
        line-height: 1.6;
      }

      @media all and (max-width: 768px) {
        font-size: 1.5rem;
        line-height: 1.5;
      }
    }

    .signUpWrapper {
      display: flex;
      margin-bottom: 25px;

      @media all and (max-width: 768px) {
        flex-direction: column;
      }

      @media all and (max-width: 576px) {
        margin-bottom: 0px;
      }

      form {
        width: 100%;

        .form-group {
          margin-bottom: 0px !important;

          input {
            padding: 6px 15px !important;
            margin-bottom: 0px !important;
            border: 1.5px solid black;
            background: rgba(255, 255, 255, 0.25);
            font-weight: 500 !important;

            &::placeholder {
              /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: rgba(0, 0, 0, 0.85);
              font-weight: 400 !important;
            }

            &::-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: rgba(0, 0, 0, 0.85);
              font-weight: 400 !important;
            }

            &::-ms-input-placeholder {
              /* Microsoft Edge */
              color: rgba(0, 0, 0, 0.85);
              font-weight: 400 !important;
            }
          }
        }
      }

      .actionBtn {
        margin-left: 12px;

        @media all and (max-width: 768px) {
          width: 100%;
          margin-left: auto;
          margin-top: 10px;
          text-align: center;
        }

        a {
          padding: 6px 15px;
          border: 1.5px solid black;
          border-radius: 4px;
          font-size: 1.4rem;
          font-weight: 600;
          word-wrap: nowrap;
          display: inline-block;
          white-space: nowrap;
          height: 100%;
          width: 100%;

          &:active {
            background: #f2f2f2;
            color: #333;
            border: 1.5px solid #333;
          }
        }
      }

      .actionBtn--dark {
        a {
          color: white;
          background: black;

          &:active {
            color: #f2f2f2;
            border: 1.5px solid #333;
            background: #333;
          }
        }
      }
    }
  }


  ///////
  /////freeSection
  //////

  .freeSection {
    padding-top: 15vh;
    padding-bottom: 10vh;

    @media all and (max-width: 1921px) {
      padding-top: 12vh;
    }

    @media all and (max-width: 1200px) {
      padding-top: 10vh;
    }

    @media all and (max-width: 768px) {
      padding-top: 8vh;
    }

    @media all and (max-width: 576px) {
      padding-bottom: 3vh;
    }

    .col {
      align-self: center;
      flex-grow: 1;
      flex-basis: 0;

      @media all and (max-width: 576px) {
        flex-grow: initial;
        flex-basis: initial;
      }
    }

    .freeImg {
      padding-right: 50px;

      @media all and (max-width: 1200px) {
        padding-right: 30px;
        padding-left: 30px;
      }

      @media all and (max-width: 576px) {
        max-width: 350px;
        margin: auto;
        margin-bottom: 40px;
      }

      img {
        width: 100%;


      }
    }

    .box {
      background: #F7F7F7;
      padding: 80px 90px;

      @media all and (max-width: 1921px) {
        padding: 70px 80px;
      }

      @media all and (max-width: 1200px) {
        padding: 50px;
      }

      @media all and (max-width: 768px) {
        padding: 30px;
      }

      @media all and (max-width: 576px) {
        background: white;
        padding: 30px 0px;
      }

      h4 {
        font-size: 3.2rem;
        font-weight: bold;
        padding-bottom: 25px;

        @media all and (max-width: 1921px) {
          font-size: 3rem;
          padding-bottom: 15px;
        }

        @media all and (max-width: 1200px) {
          font-size: 2.6rem;
        }

        @media all and (max-width: 576px) {
          font-size: 2.2rem;
        }
      }

      p {
        font-weight: 300;
        font-size: 2rem;
        line-height: 180%;
        padding-top: 10px;

        @media all and (max-width: 1921px) {
          font-size: 1.8rem;
          padding-top: 5px;
        }

        @media all and (max-width: 1200px) {
          font-size: 1.6rem;
          line-height: 1.5;
        }

        @media all and (max-width: 576px) {
          font-size: 1.5rem;
        }
      }
    }
  }

  ///////
  //////trial section
  //////

  .trialSection {
    padding-top: 10vh;
    padding-bottom: 15vh;

    @media all and (max-width: 1921px) {
      // .container{
      //   max-width: 1080px;
      //   padding-left: 25px;
      //   padding-right: 25px;
      // }
    }

    @media all and (max-width: 1200px) {
      padding-top: 3vh;
      padding-bottom: 10vh;
    }

    @media all and (max-width: 576px) {
      padding-top: 0px;
    }

    .col {
      align-self: center;
    }

    .trialBox {
      background: #F7F7F7;
      padding: 80px 90px;
      text-align: center;

      @media all and (max-width: 1921px) {
        padding: 70px 80px;
      }

      @media all and (max-width: 1200px) {
        padding: 50px 30px;
        // max-width: 350px;
        margin: auto;
      }

      h5 {
        font-size: 2.4rem;
        font-weight: 500;

        @media all and (max-width: 1921px) {
          font-size: 2.2rem;
        }

        @media all and (max-width: 1200px) {
          font-size: 2rem;
        }
      }

      .price {
        font-weight: 400;
        font-size: 6.5rem;
        line-height: 1.5;
        color: #FAC85A;
        padding-top: 40px;
        padding-bottom: 40px;

        @media all and (max-width: 1921px) {
          padding-top: 30px;
          padding-bottom: 30px;
          font-size: 6rem;
        }

        @media all and (max-width: 1200px) {
          font-size: 4rem;
          padding-top: 15px;
          padding-bottom: 15px;
        }

        span {
          margin-left: 5px;
          font-weight: 400;
          font-size: 2.8rem;
          line-height: 1.5;
          color: black;

          @media all and (max-width: 1921px) {
            font-size: 2.6rem;
          }


          @media all and (max-width: 1200px) {
            font-size: 2rem;

          }
        }
      }

      p {
        font-weight: 300;
        font-size: 2rem;
        line-height: 180%;
        padding-top: 10px;

        @media all and (max-width: 1921px) {
          font-size: 1.8rem;
          padding-top: 10px;
          margin-bottom: 0px;
        }

        @media all and (max-width: 1200px) {
          font-size: 1.6rem;
          padding-top: 5px;
        }
      }
    }

    .arrowImg {
      @media all and (max-width: 576px) {
        margin-top: 15px;
        margin-bottom: 15px;
      }

      img {
        width: 100%;

        @media all and (max-width: 1200px) {
          max-height: 240px;
          padding-top: 100px;
          transform: scaleX(-1) scaleY(-1) rotate(-90deg);
        }

        @media all and (max-width: 576px) {
          margin-left: 50px;
        }
      }
    }

    .signUpWrapper {

      @media all and (max-width: 1200px) {
        max-width: 600px;
      }

      h5 {
        font-weight: bold;
        font-size: 2.6rem;
        line-height: 130%;
        max-width: 320px;

        @media all and (max-width: 1921px) {
          font-size: 2.3rem;
        }

        @media all and (max-width: 1200px) {
          font-size: 2rem;
        }
      }

      p {
        padding-top: 15px;
        font-weight: 300;
        font-size: 2rem;
        line-height: 180%;

        @media all and (max-width: 1921px) {
          font-size: 1.8rem;
        }

        @media all and (max-width: 1200px) {
          font-size: 1.6rem;
          padding-top: 5px;
        }
      }

      h6 {
        padding-top: 50px;
        padding-bottom: 15px;
        font-weight: 400;
        font-size: 2rem;
        line-height: 130%;
        padding-right: 25%;

        @media all and (max-width: 1921px) {
          font-size: 1.8rem;
        }

        @media all and (max-width: 1200px) {
          font-size: 1.6rem;
          padding-top: 25px;
        }
      }

      .formBox {
        display: flex;

        @media all and (max-width: 576px) {
          flex-direction: column;
        }
      }


      form {
        width: 100%;

        .form-group {
          margin-bottom: 0px !important;

          input {
            padding: 6px 15px !important;
            margin-bottom: 0px !important;
            border: 1.5px solid black;

          }
        }
      }

      .actionBtn {
        margin-left: 12px;

        @media all and (max-width: 576px) {
          margin-left: 0px;
          margin-top: 10px;
        }

        @media all and (max-width: 576px) {
          text-align: center;
        }

        a {
          padding: 6px 15px;
          border: 1.5px solid black;
          border-radius: 4px;
          font-size: 1.4rem;
          font-weight: 600;
          word-wrap: nowrap;
          display: inline-block;
          white-space: nowrap;
          height: 100%;
          width: 100%;

          &:active {
            background: #f2f2f2;
            color: #333;
            border: 1.5px solid #333;
          }
        }
      }

      .actionBtn--orange {
        a {
          color: black;
          background: #FAC85A;
          border: 1.5px solid #FAC85A;

          &:active {
            color: black;
            border: 1.5px solid darken(#FAC85A, 7%);
            background: darken(#FAC85A, 7%);
          }
        }
      }
    }

  }

  /////
  ////contactSection
  ////

  .contactSection {
    padding-top: 10vh;
    padding-bottom: 10vh;

    @media all and (max-width: 1200px) {
      padding-top: 5vh;
      padding-bottom: 5vh;
    }

    .titleBox {
      text-align: center;
      padding-bottom: 10vh;

      @media all and (max-width: 1200px) {
        padding-bottom: 3vh;
      }

      h4 {
        font-size: 3.2rem;
        font-weight: bold;

        @media all and (max-width: 1921px) {
          font-size: 3rem;
        }

        @media all and (max-width: 1200px) {
          font-size: 2.8rem;
        }

        @media all and (max-width: 576px) {
          font-size: 2.2rem;
        }
      }

      p {
        font-weight: 300;
        font-size: 2rem;
        line-height: 1.5;

        @media all and (max-width: 1921px) {
          font-size: 1.8rem;
        }

        @media all and (max-width: 1200px) {
          font-size: 1.6rem;
          padding-left: 15px;
          padding-right: 15px;
          margin-bottom: 0px;
        }
      }
    }

    .col {
      align-self: center;
    }

    .authorCol {
      text-align: center;

      @media all and (max-width: 1200px) {
        display: none;
      }

      img {
        width: 100%;
        padding-right: 10%;
        padding-left: 10%;
        margin-bottom: 20px;

        @media all and (max-width: 1921px) {
          margin-bottom: 15px;
          max-width: 350px;
        }

        @media all and (max-width: 1200px) {
          max-width: 500px;
        }
      }

      p {
        padding-top: 3px;
        padding-bottom: 3px;
        font-weight: 300;
        font-size: 1.8rem;
        line-height: 180%;

        @media all and (max-width: 1921px) {
          font-size: 1.6rem;
          padding-top: 3px;
          padding-bottom: 3px;
          margin-bottom: 5px;
          line-height: 160%;
        }

        @media all and (max-width: 1200px) {
          margin-bottom: 0px;
          padding-top: 0px;
          padding-bottom: 0px;
          font-size: 1.5rem;
        }
      }

      .author {
        font-weight: normal;
        font-size: 1.5rem;
        line-height: 130%;
        margin-top: 25px;

        @media all and (max-width: 1921px) {
          font-size: 1.4rem;
          margin-top: 15px;
        }

        span {
          color: #9C9C9C;
        }
      }
    }

    .contactCol {
      padding-left: 50px;
      padding-bottom: 30px;

      @media all and (max-width: 1200px) {
        padding-left: 15px;
        padding-bottom: 0px;
      }

      h5 {
        font-weight: 500;
        font-size: 1.8rem;
        padding-bottom: 20px;
        line-height: 130%;

        @media all and (max-width: 1921px) {
          font-size: 1.6rem;
          padding-bottom: 10px;
        }

        @media all and (max-width: 1200px) {
          display: none;
        }
      }

      .formBox {

        display: flex;

        @media all and (max-width: 1200px) {
          flex-direction: column;
        }

        form {
          width: 100%;

          .form-group {
            margin-bottom: 0px !important;

            input {
              padding: 6px 15px !important;
              margin-bottom: 0px !important;
              border: 1.5px solid black;

              @media all and (max-width: 1200px) {
                padding: 6px 10px !important;
              }
            }

            textarea {
              margin-bottom: 15px;
              border: 1.5px solid black;

              @media all and (max-width: 1200px) {
                padding: 6px 10px !important;
              }
            }
          }
        }

        .actionBtn {
          margin-left: 12px;

          @media all and (max-width: 1200px) {
            margin-left: 0px;
            margin-top: 10px;
          }

          span {
            padding: 6px 15px;
            border: 1.5px solid black;
            border-radius: 4px;
            font-size: 1.4rem;
            font-weight: 600;
            word-wrap: nowrap;
            display: inline-block;
            white-space: nowrap;
            height: 100%;
            width: 100%;
            cursor: pointer;
            user-select: none;

            @media all and (max-width: 1200px) {
              text-align: center;
            }

            &:active {
              background: #f2f2f2;
              color: #333;
              border: 1.5px solid #333;
            }
          }
        }

        .actionBtn--dark {
          span {
            color: white;
            background: black;

            &:active {
              color: #f2f2f2;
              border: 1.5px solid #333;
              background: #333;
            }
          }
        }
      }
    }
  }


  ///////
  ////// footerActionSection
  //////

  .footerActionSection {
    background: #F7F7F7;
    padding-top: 5vh;
    padding-bottom: 3vh;

    @media all and (max-width: 1921px) {
      padding-bottom: 10px;
    }

    @media all and (max-width: 576px) {
      padding-top: 3vh;
    }

    @media all and (max-width: 1200px) {
      .col {
        flex-grow: 1;
        flex-basis: 0;
      }
    }

    .colHand {
      width: 100%;

      @media all and (max-width: 576px) {
        display: none;
      }
    }

    .colHand--right {
      transform: scaleX(-1);

      @media all and (max-width: 768px) {
        display: none;
      }
    }


    h4 {
      text-align: center;
      font-size: 3.2rem;
      font-weight: bold;
      line-height: 1.3;
      padding-top: 5vh;

      @media all and (max-width: 1921px) {
        font-size: 3rem;
      }

      @media all and (max-width: 1200px) {
        font-size: 2.8rem;
      }

      @media all and (max-width: 768px) {
        font-size: 2.5rem;
      }

      @media all and (max-width: 576px) {
        font-size: 2.2rem;
      }
    }


    .signUpWrapper {
      display: flex;
      margin-bottom: 25px;
      padding-left: 20%;
      padding-right: 20%;
      padding-top: 7vh;

      @media all and (max-width: 1200px) {
        flex-direction: column;
        padding-left: 15px;
        padding-right: 15px;
      }

      @media all and (max-width: 576px) {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 3vh;
        padding-bottom: 5vh;
      }

      form {
        width: 100%;

        .form-group {
          margin-bottom: 0px !important;

          input {
            padding: 6px 15px !important;
            margin-bottom: 0px !important;
            border: 1.5px solid black;

          }
        }
      }

      .actionBtn {
        margin-left: 12px;

        @media all and (max-width: 1200px) {
          margin-left: 0px;
          margin-top: 10px;
        }

        @media all and (max-width: 576px) {
          text-align: center;
        }

        a {
          padding: 6px 15px;
          border: 1.5px solid black;
          border-radius: 4px;
          font-size: 1.4rem;
          font-weight: 600;
          word-wrap: nowrap;
          display: inline-block;
          white-space: nowrap;
          height: 100%;
          width: 100%;

          &:active {
            background: #f2f2f2;
            color: #333;
            border: 1.5px solid #333;
          }
        }
      }

      .actionBtn--dark {
        a {
          color: white;
          background: black;

          &:active {
            color: #f2f2f2;
            border: 1.5px solid #333;
            background: #333;
          }
        }
      }
    }
  }

  //////
  /////Footer
  /////

  footer {
    height: 90px;
    background: #F7F7F7;
    background: white;
    padding-top: 5px;
    width: 100%;
    align-items: center;
    display: flex;

    @media all and (max-width: 1921px) {
      height: 80px;
    }

    @media all and (max-width: 1200px) {
      height: auto;
      padding-bottom: 20px;
      padding-top: 30px;
      background: #FAC85A;
    }

    .col {
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 170%;

      // line-height: 70px;
      @media all and (max-width: 1921px) {
        font-size: 1.5rem;
      }

      @media all and (max-width: 1200px) {
        line-height: 2.5;
      }

      a {
        font-weight: 500;

        &:hover {
          color: #FAC85A;
        }
      }
    }

    .colMiddle {
      text-align: center;
      line-height: 300%;

      @media all and (max-width: 1921px) {
        padding-top: 5px;
      }

      @media all and (max-width: 1200px) {
        padding-top: 10px;
        padding-bottom: 10px;
      }

      a {
        margin-right: 15px;
        padding: 10px;
        filter: brightness(0);

        &:hover {
          filter: brightness(1);
        }
      }
    }

    .colRight {
      text-align: right;

      @media all and (max-width: 1200px) {
        text-align: center;
      }
    }

    .colLeft {
      @media all and (max-width: 1200px) {
        text-align: center;
      }
    }
  }

  /////////// end of sections
}