.globalPreloader {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: -1;
  justify-content: center;
  background: rgba(255, 255, 255, 0);

  .microSpinner {
    font-size: 40px;
    margin: 50px;
  }
}

.isSpinner {
  z-index: 998;
  background: rgba(255, 255, 255, 1);
  animation: loadDelay 2.5s normal forwards ease-in-out;
  animation-iteration-count: 1;

  @keyframes loadDelay {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}