.embed-panel {
  opacity: 0;
  animation: loadDelay 0.3s normal forwards ease-in-out;
  animation-iteration-count: 1;

  @keyframes loadDelay {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}