.blog-page {
  height: 100vh;
  min-height: calc(100vh + 1px);
  background-color: #f7f7f7;
  // background-image: url('./../../assets/bg.svg'); //#f9f9f9;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  .row {
    height: 100%;

    .col-xl-6,
    .col-xl-7 {
      margin: auto;

      .signInForm {
        margin: auto;
      }

      img {
        width: 95%;
      }
    }
  }

  h5{
    font-size: 3.2rem;
    font-weight: 900;
  }

  .liveButton {
    text-align: center;
    margin-top: 30px;
    background: #FAC85A;
    display: inline-flex;
    justify-self: center;
    justify-content: center;
    padding: 25px 40px;
    font-weight: bold;
    font-size: 1.8rem;
    border-radius: 5px;
    cursor: pointer;

    i{
      margin-top: 0px;
      font-size: 2.5rem;
      padding-right: 10px;
    }
  }
}