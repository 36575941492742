.kalibracja-simple {

  .productView {
    min-height: 35vh;
    max-height: 35vh;
    overflow-y: auto;

    .product-img {
      width: 100%;
      max-width: 300px;
      min-height: 22vh;
      max-height: 22vh;
      background-color: white;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      padding: 15px;
      border-radius: 4px;
      margin-left: 2rem;
      margin-right: 2rem;
      border: 10px solid white;
    }


    h5 {
      padding-left: 2rem;
      padding-right: 2rem;
      margin-bottom: 1rem;
      font-size: 1.6rem;
    }

    h3 {
      margin-top: 1rem;
      margin-bottom: 2rem;
      font-size: 2.4rem;
      font-weight: 700;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    p {
      padding-left: 2rem;
      padding-right: 2rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: 1.6rem;
      line-height: 160%;
    }
  }

  .kalibracja-simple_productsNav {
    margin-top: 5rem;
    margin-bottom: 3rem;

    p {
      padding-left: 2rem;
      font-size: 1.6rem;
      font-weight: bold;
    }

    .kalibracja-simple_productsNav-item {
      font-size: 1.6rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      background: white;
      padding: 1.5rem 2rem;
      border-radius: 4px;
      cursor: pointer;
      transition-duration: 0.2s;

      .simulationResult {
        margin-right: 16px;
        float: right;
      }

      i {
        float: right;
        transition-duration: 0.2s;
        line-height: 24px;
      }

      @keyframes rotating {
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
        }
      }

      .waitingForSimulation {
        animation: rotating 1s linear infinite;
      }

      &:hover {
        margin-left: 5px;
        transition-duration: 0.2s;

        i {
          transition-duration: 0s;
        }
      }

      &.kalibracja-simple_productsNav-item--disabled {
        cursor: wait;
        color: #bbb;

        &:hover {
          margin-left: 0px;
          transition-duration: 0.2s;

          i {
            transition-duration: 0s;
          }
        }
      }
    }

    .active {
      color: #FAC85A;

      i {
        color: #FAC85A;
      }
    }
  }

  .variants-col {
    h3 {
      font-size: 2.4rem;
      font-weight: 700;
    }

    .questions-nav-calibration {
      margin-bottom: 3rem;
      text-align: right;
      display: flex;
      text-align: center;
      align-items: center;

      .questionsHeader {
        font-weight: 700;
        font-size: 1.6rem;
        color: #FAC85A;
      }

      button {
        box-shadow: none !important;

        &:first-of-type {
          margin-right: auto;
        }

        &:last-of-type {
          margin-left: auto;
        }
      }
    }
  }

  .calibrationCard {
    padding: 2rem;
    background: white;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-radius: 4px;

    .stateDescription {
      color: #FAC85A;
    }

    .calibrationCard_header {
      display: flex;

      h4 {
        font-size: 1.6rem;
        font-weight: 500;
      }

      .control-info-panel {
        display: flex;
        align-items: center;
        margin-left: auto;

        i {
          font-size: 3rem;
          color: lighten(#FAC85A, 25);
          cursor: pointer;
          padding: 2px;
          width: 30px;
          text-align: center;
          border-radius: 50px;
          // &:hover {
          //   box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
          // }

          // &:active {
          //   box-shadow: 0 1px 5px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
          //   background: #fafafa;
          // }
        }

        .active {
          color: #FAC85A;
        }

        .currentValue {
          font-size: 1.6rem;
          line-height: 140%;
          padding: 4px 30px;
          border-radius: 50px;
          margin-right: 15px;
          margin-left: 15px;
          background: #f5f5f5;
          min-width: 90px;
          text-align: center;
        }
      }
    }

    .calibSelectWrapper {
      width: 100%;
      display: flex;
      margin-top: 4rem;

      .calibSelect {
        width: 100%;
        margin: 1%;
        cursor: pointer;
        border-radius: 10px;
        background: lighten(#FAC85A, 30);
        transition-duration: 0.2s;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        font-size: 1.25rem;
        color: #555;

        &:hover {
          transform: translateY(-2%);
          background: lighten(#FAC85A, 25);
        }
      }

      .calibSelect.filled {
        background: #FAC85A;
        color: white;

        // margin-top: 50px;
        &:hover {
          background: lighten(#FAC85A, 5);
        }
      }

      .calibSelect0 {
        height: 20px;
        margin-top: 100px;
      }

      .calibSelect2 {
        height: 30px;
        margin-top: 90px;
      }

      .calibSelect4 {
        height: 40px;
        margin-top: 80px;
      }

      .calibSelect6 {
        height: 50px;
        margin-top: 70px;
      }

      .calibSelect8 {
        height: 60px;
        margin-top: 60px;
      }

      .calibSelect10 {
        height: 70px;
        margin-top: 50px;

      }

      .calibSelect12 {
        height: 80px;
        margin-top: 40px;
      }

      .calibSelect14 {
        height: 90px;
        margin-top: 30px;
      }

      .calibSelect16 {
        height: 100px;
        margin-top: 20px;
      }

      .calibSelect18 {
        height: 110px;
        margin-top: 10px
      }

      .calibSelect20 {
        height: 120px;
        margin-top: 0px;
      }
    }
  }
}